import React from 'react'
import FullCustomerDiscoveryComponent from '../components/CustomerDiscovery/FullCustomerDiscoveryComponent'
import Layout from '../components/jsComponents/layout'
import SEO from '../components/jsComponents/seo'
import LoadingOverlay from '../components/ux/LoadingOverlay'

interface IProps { }

interface IState {
  loading: boolean
  theEnd: boolean
}

class IndexClass extends React.Component<IProps, IState> {
  state: IState = {
    loading: true,
    theEnd: false,
  }

  componentDidMount() {
    // Set here to set a timeout for the average loading time
    // this.setState({ loading: false })
    setTimeout(() => this.setState({ loading: false }), 500)
  }

  render() {
    return (
      <Layout loading={this.state.loading} theEnd={this.state.theEnd}>
        <SEO title="Home" />
        <div style={{ visibility: this.state.loading ? 'hidden' : undefined }}>
          <FullCustomerDiscoveryComponent
            theEnd={() => this.setState({ theEnd: true })}
          />
        </div>
        <LoadingOverlay loading={this.state.loading} />
      </Layout>
    )
  }
}

const IndexPage = () => <IndexClass />

export default IndexPage
